import React from 'react';

const isClient = typeof window != 'undefined';
const initialAtTop = isClient && window.pageYOffset != 0 ? false : true;

export function useIsAtTop(): boolean {
  const [isAtTop, setIsAtTop] = React.useState(initialAtTop);

  React.useEffect(() => {
    function handleScroll() {
      setIsAtTop(window.pageYOffset == 0);
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isAtTop;
}
