import React from 'react';
import clsx from 'clsx';
import styles from './Header.module.css';
import Link from 'next/link';
import Image, { StaticImageData } from 'next/image';
import Navigation from './Navigation';
import { useIsAtTop } from '../../hooks/useIsAtTop';
import { useTheme } from '@mui/material';

interface Props {
  title: string;
  background_image?: StaticImageData;
  height?: string | number;
}

export default function Header(props: Props) {
  const isAtTop = useIsAtTop();
  const theme = useTheme();

  return (
    <header className={styles.header}>
      <div className={isAtTop ? styles.headerMain : styles.headerMainScrolled}>
        <div className={clsx(styles.headerTop, styles.background)} style={{ opacity: isAtTop ? 0 : 1 }} />
        <div className={styles.centeredLarge}>
          <div className={styles.headerTopInner}>
            <div className={styles.logo}>
              <div className={styles.logoContent}>
                <h1 className={styles.logoTextLarge}>
                  <Link
                    href="/"
                    style={{
                      fontWeight: 500,
                      color: '#fff',
                      cursor: 'pointer',
                      textDecoration: 'none',
                    }}
                  >
                    Fosterburg Baptist Church
                  </Link>
                </h1>
              </div>
            </div>
            <Navigation />
          </div>
        </div>
      </div>
      <div className={clsx(styles.background, styles.banner)}>
        {props.background_image ? (
          <Image
            alt="Header Background"
            src={props.background_image}
            quality={100}
            priority
            fill
            sizes="100vw"
            style={{
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              opacity: '0.3',
            }}
          />
        ) : null}
        <div className={clsx(styles.bannerInner, styles.centeredLarge)}>
          <div className={styles.bannerTitle} style={props.height ? { height: props.height } : {}}>
            <div className={styles.bannerH1}>{props.title}</div>
          </div>
        </div>
      </div>
    </header>
  );
}
