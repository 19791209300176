import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useAjax } from '@fosterburg/common-ui/hooks/useAjax';
import * as notificationAjax from '../../ajax/site_notifications';
import { Fab, Tooltip, useTheme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const usePersistentState = (initialState, name) => {
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    const savedState = typeof localStorage != 'undefined' && localStorage.getItem(name);

    if (savedState) {
      setState(JSON.parse(savedState));
    }
  }, []);

  const wrappedSetState = (updater) => {
    if (typeof updater == 'function') {
      setState((prev) => {
        const newState = updater(prev);
        localStorage.setItem(name, JSON.stringify(newState));
        return newState;
      });
    } else {
      const newState = updater;
      localStorage.setItem(name, JSON.stringify(newState));
      setState(newState);
    }
  };

  return [state, wrappedSetState];
};

function SiteNotificationModal() {
  const [site_notifications, getSiteNotifications, getSiteNotificationMeta] = useAjax(notificationAjax.getSiteNotifications, []);
  const [dismissedNotifications, setDismissedNotifications] = usePersistentState([], 'dismissed_notifications');

  const theme = useTheme();

  React.useEffect(() => {
    setTimeout(() => {
      getSiteNotifications();
    }, 2000);
  }, []);

  // Clean up localstorage
  React.useEffect(() => {
    if (getSiteNotificationMeta.success) {
      const cleanedDismissedNotifications = dismissedNotifications.filter((dismissed) => !!site_notifications.find((n) => n.id == dismissed));
      if (cleanedDismissedNotifications.length != dismissedNotifications.length) {
        setDismissedNotifications(cleanedDismissedNotifications);
      }
    }
  }, [dismissedNotifications, site_notifications, getSiteNotificationMeta]);

  const handleClose = (id) => {
    setDismissedNotifications((prev) => [...prev, id]);
  };

  const activeNotifications = site_notifications.reduce((acc, notification) => {
    if (!dismissedNotifications.includes(notification.id)) {
      acc.push(notification);
    }
    return acc;
  }, []);

  return (
    <>
      <Dialog open={activeNotifications.length > 0} aria-labelledby="site-notification">
        <DialogTitle id="site-notification">Notice: {activeNotifications[0]?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{activeNotifications[0]?.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(activeNotifications[0]?.id)}>
            {activeNotifications.length > 1 ? `Next (${activeNotifications.length - 1})` : 'Dismiss'}
          </Button>
        </DialogActions>
      </Dialog>
      {dismissedNotifications.length > 0 ? (
        <Tooltip title="Show dismissed alerts">
          <Fab
            sx={{
              position: 'fixed',
              bottom: theme.spacing(2),
              right: theme.spacing(2),
            }}
            variant="extended"
            color="secondary"
            aria-label="add"
            onClick={() => setDismissedNotifications([])}
          >
            <ErrorOutlineIcon sx={{ mr: 1 }} /> Alerts
          </Fab>
        </Tooltip>
      ) : null}
    </>
  );
}

export default SiteNotificationModal;
