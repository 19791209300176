import { ajax } from '@fosterburg/common-ui/utils/ajax';
import { prefix } from './prefix';

export async function getSiteNotifications(currentOnly = true) {
  return await ajax({
    url: prefix + '/site-notifications',
    data: {
      currentOnly,
    },
  });
}
