import React from 'react';
import clsx from 'clsx';
import styles from './Footer.module.css';
import { Typography } from '@mui/material';

export default function Footer() {
  return (
    <footer className={clsx(styles.background, styles.footerMain)}>
      <div className={styles.footerList}></div>
      <div className={styles.footerCopyright}>
        <Typography>&copy; {new Date().getFullYear()} - Fosterburg Baptist Church</Typography>
      </div>
    </footer>
  );
}
