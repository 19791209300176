import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Collapse, Grid, Grow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuUnstyled from '@mui/base/MenuUnstyled';
import MenuItemUnstyled, { menuItemUnstyledClasses } from '@mui/base/MenuItemUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import styles from './Header.module.css';
import clsx from 'clsx';
import Link from 'next/link';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import { navConfig } from '../../utils/constants';

export default function Navigation() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const preventReopen = React.useRef(false);

  const [isSubmenuOpen, setIsSubmenuOpen] = React.useState({});

  const handleMenuIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (preventReopen.current) {
      event.preventDefault();
      preventReopen.current = false;
      return;
    }
    if (!isOpen) {
      setAnchorEl(event.currentTarget);
    }
  };

  const toggleSubmenuOpen = (name) => {
    setIsSubmenuOpen((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsSubmenuOpen({});
  };
  const handleButtonMouseDown = () => {
    if (isOpen) {
      // Prevents the menu from reopening right after closing
      // when clicking the button.
      preventReopen.current = true;
    }
  };

  return (
    <nav className={styles.headerMenu}>
      <div className={styles.fullSizeNav}>
        <ul className={clsx(styles.headerMenuContent, styles.sfMenu)} style={{ touchAction: 'pan-y' }}>
          {navConfig.map((item) => {
            if (item.type == 'link') {
              return (
                <li key={item.name}>
                  <Link href={item.url}>{item.name}</Link>
                </li>
              );
            } else if (item.type == 'group') {
              return (
                <li key={item.name} className={styles.hoverMenu}>
                  {item.name}
                  <ul className={styles.subMenu}>
                    {item.subMenu.map((subItem) => (
                      <li key={subItem.name} style={{}}>
                        <Link href={subItem.url}>
                          <Typography color={(theme) => theme.palette.primary.main} variant="subtitle2">
                            {subItem.name}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            }
          })}
        </ul>
      </div>
      <div className={styles.smallSizeNav}>
        <div className={styles.headerMenuContent}>
          <IconButton onClick={handleMenuIconClick} aria-label="nav-menu" onMouseDown={handleButtonMouseDown}>
            <MenuIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
        <MenuUnstyled
          open={isOpen}
          onClose={handleMenuClose}
          slots={{ root: Popper, listbox: StyledListbox }}
          anchorEl={anchorEl}
          slotProps={{ listbox: { id: 'nav-menu' } }}
        >
          <Grow in={isOpen}>
            <div>
              {navConfig.map((item) => {
                if (item.type == 'link') {
                  return (
                    <StyledMenuItem key={item.name}>
                      <Link href={item.url}>
                        <Typography variant="h6">{item.name}</Typography>
                      </Link>
                    </StyledMenuItem>
                  );
                } else if (item.type == 'group') {
                  return (
                    <React.Fragment key={item.name}>
                      <StyledMenuItem onClick={() => toggleSubmenuOpen(item.name)}>
                        <Grid container sx={{ width: '100%' }}>
                          <Grid item xs={11}>
                            <Typography variant="h6">{item.name}</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            {isSubmenuOpen[item.name] ? <ExpandLessIcon style={{ marginTop: 5 }} /> : <ExpandMoreIcon style={{ marginTop: 5 }} />}
                          </Grid>
                        </Grid>
                      </StyledMenuItem>
                      <Collapse in={isSubmenuOpen[item.name]}>
                        {item.subMenu.map((subItem) => (
                          <StyledMenuItem key={subItem.name} sx={{ paddingLeft: 8 }}>
                            <Link href={subItem.url}>
                              <Typography variant="body1">{subItem.name}</Typography>
                            </Link>
                          </StyledMenuItem>
                        ))}
                      </Collapse>
                    </React.Fragment>
                  );
                }
              })}
            </div>
          </Grow>
        </MenuUnstyled>
      </div>
    </nav>
  );
}

const Popper = styled(PopperUnstyled)`
  z-index: 101;
  width: 100%;
`;

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 11px 0;
  width: 100%;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.primary.main};
  color: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  `,
);

const StyledMenuItem = styled(MenuItemUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    padding-left: 35px;
    padding-right: 35px;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemUnstyledClasses.focusVisible} {
      outline: 3px solid ${grey[900]};
      background-color: ${grey[100]};
      color: ${grey[900]};
    }
  
    &.${menuItemUnstyledClasses.disabled} {
      color: ${grey[400]};
    }
  
    &:hover:not(.${menuItemUnstyledClasses.disabled}) {
      background-color: ${grey[100]};
      color: ${grey[900]};
    }
    `,
);
